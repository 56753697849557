<template>
  <div style="padding: 98px 42px 0 42px">
    <div class="logo">
      <img src="../assets/icon_yejiao_logo.png" alt="" />
    </div>
    <!-- <div class="Entrusted_status">委托身份</div> -->
    <div class="Entrusted_img">
      <img v-if="detail.valid == true" src="../assets/Maskgroup.png" alt="" />
      <img v-if="detail.valid == false" src="../assets/Mask_group.png" alt="" />
    </div>
    <div class="Entrusted_content">
      <div>{{ detail.otherName }}</div>
      <div>{{ detail.otherIdNo }}</div>
      <div>委托人：{{ detail.userName }}</div>
    </div>
    <div v-if="detail.valid == true" class="Entrusted_time">
      {{ strDate }}
    </div>
    <div v-if="detail.valid == false" class="Entrusted_time">委托过期</div>
    <div class="identitycard">*来访时请务必携带本人身份证</div>
  </div>
</template>
<script>
// import 'vant/lib/button/style'
// import QRCode from 'qrcodejs2'
export default {
  components: {},
  data() {
    return {
      strDate: "",
      detail: {},
      // baseurl: "http://192.168.1.195:24002", //测试
      // baseurl: "https://newsapi.lemlink.com", //临时生产
         baseurl: "https://entrusted.zhongzhi.com.cn", //生产
    };
  },
  created() {
       this.routercode = this.$router.history.current.query.code;
      //  console.log(this.routercode)
  },
  mounted() {
    setInterval(() => {
      this.currentTime();
    }, 1000);
    this.$http
      .post(
        `${this.baseurl}/api/pass/info?code=${this.routercode}`
      )
      .then((res) => {
        this.detail = res.data.data;
        //身份证号码隐藏
        this.detail.otherIdNo = this.detail.otherIdNo.replace(
          /(\d{6})\d{8}(\d{4})/,
          "$1********$2"
        );
      });
  },
  methods: {
    currentTime() {
      var date = new Date();
      var year = date.getFullYear(); //月份从0~11，所以加一
      let month = date.getMonth();
      var dateArr = [
        date.getMonth() + 1,
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
      ];
      //如果格式是MM则需要此步骤，如果是M格式则此循环注释掉
      for (var i = 0; i < dateArr.length; i++) {
        if (dateArr[i] >= 1 && dateArr[i] <= 9) {
          dateArr[i] = "0" + dateArr[i];
        }
      }
      var strDate =
        year +
        "-" +
        dateArr[0] +
        "-" +
        dateArr[1] +
        " " +
        dateArr[2] +
        ":" +
        dateArr[3] +
        ":" +
        dateArr[4];
      this.strDate = strDate;
    },
    handleclick() {
      //   this.$http.post("http://47.104.250.247:17999/api/pay/h5").then((res) => {
      //     this.prepay_id = res.data.data.prepay_id;
      //     this.mweb_url = res.data.data.mweb_url;
      //     console.log(res.data.data.mweb_url);
      //     window.location.href =
      //       this.mweb_url +
      //       "&redirect_url=" +
      //       encodeURI("http://qfl.lemlink.com/a/b.html");
      //   });
      // this.$http
      //   .post('http://qfl-api.lemlink.com/api/pay/native')
      //   .then((res) => {
      //     this.code_url = res.data.data.code_url
      //     console.log(res.data.data.code_url)
      //     this.qrcode()
      //   })
      // this.$nextTick(() => {
      //   console.log(this.code_url)
      //   document.getElementById('qrcode').innerHTML = ''
      // })
    },
    // handleclickh5 () {
    //   this.$http.post('http://qfl-api.lemlink.com/api/pay/h5').then((res) => {
    //     this.mweb_url = res.data.data.mweb_url
    //     window.location.href =
    //       this.mweb_url +
    //       '&redirect_url=' +
    //       encodeURI('http://qfl.lemlink.com')
    //   })
    // },
    // handlemore () {
    //   this.$router.push({
    //     name: 'more'
    //   })
    // }
  },
};
</script>
<style lang="scss" scoped>
.Entrusted_status {
  font-size: 30px;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: bold;
  color: #0a7e3c;
  line-height: 35px;
  text-align: center;
}
.Entrusted_img {
  width: 70.5%;
  height: 25%;
  margin: 0 auto;
  margin-top: 21px;
  img {
    width: 100%;
    height: 100%;
  }
}
.Entrusted_content {
  margin-top: 26px;
  div {
    font-size: 15px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    line-height: 18px;
    text-align: center;
    margin-bottom: 13px;
  }
}
.Entrusted_time {
  margin-top: 38px;
  height: 72px;
  background-image: url("../assets/Group1017.png");
  background-size: 100% 100%;
  font-size: 22px;
  font-family: Source Han Sans CN-Heavy, Source Han Sans CN;
  font-weight: 800;
  color: #c49d62;
  line-height: 72px;
  letter-spacing: 1px;
  text-align: center;
}
.identitycard {
  font-size: 13px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  line-height: 15px;
  position: absolute;
  bottom: 34px;
  left: 50%;
  transform: translateX(-50%);
}
.logo {
  width: 48.4%;
  height: 2.4%;
  margin: 0 auto;
  margin-bottom: 20px;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
<style scoped>
.van-list {
  height: 220px;
  overflow-y: scroll;
  background-color: aliceblue;
}
::v-deep .van-button--round {
  width: 130px;
  height: 44px;
  line-height: 44px;
}
</style>
